import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import Title from './Title'
import Line from './Line'
import FooterBox from './FooterBox'
import T from '../lib/intl/T'
import { up, addPagePadding } from '../lib/styles'

import logoPath from '../data/images/logo-white.png'

const iconsPaths = {
  pin: require('../data/images/icons/pin.svg'),
  listPoint: require('../data/images/icons/list-point.svg'),
  email: require('../data/images/icons/email.svg'),
  person: require('../data/images/icons/person.svg'),
  phone: require('../data/images/icons/phone.svg'),
}

const Wrapper = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    /* background-color: ${colors.darkGreen}; */
    background: ${colors.greenGradient};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 58px 40px 0;
    ${up('menu')} {
      padding: 84px 40px 0;
    }
  `}
`
const Icon = styled.img`
  max-width: 18px;
  max-height: 18px;
  ${up('menu')} {
    max-width: none;
    max-height: none;
  }
`

const Logo = styled.img`
  width: 150px;
  /* height: 56px; */
  ${up('menu')} {
    width: 200px;
    /* height: 75px; */
  }
`

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    // backgroundColor: 'red',
    padding: 16,
    fontSize: 15,
    borderRadius: 0,
  },
  // arrow: {
  //   color: colors.black,
  // },
}))(Tooltip)

const Block = ({ iconName, children, isListPoint, tooltip, smallGap }) => {
  const tooltipProps = {
    title: tooltip,
  }
  if (!tooltip) tooltipProps.open = false
  return (
    <Col>
      <Row alignItems={isListPoint && 'center'}>
        <Col>
          <Icon
            src={iconsPaths[iconName || (isListPoint ? 'listPoint' : null)]}
            alt={iconName}
          />
        </Col>
        <Gap gap="20px" mobileGap="16px" />
        <StyledTooltip arrow placement="top" {...tooltipProps}>
          <Col>
            <Text.Footer>{children}</Text.Footer>
          </Col>
        </StyledTooltip>
      </Row>
      {!isListPoint && !smallGap && <Gap gap="24px" mobileGap="12px" />}
      {isListPoint && <Gap gap="8px" mobileGap="4px" />}
      {smallGap && <Gap gap="4px" mobileGap="4px" />}
    </Col>
  )
}

const FooterTitle = ({ children }) => (
  <Col>
    <Title.Footer>
      <T>{children}</T>
    </Title.Footer>
    <Gap mobileGap="16px" gap="30px" />
  </Col>
)

const Footer = ({ boxVariant, footerBoxBg }) => {
  const [boxHeight, setBoxHeight] = useState(0)

  return (
    <>
      {boxVariant !== 'none' && (
        <FooterBox
          variant={boxVariant}
          bgColor={footerBoxBg}
          onHeightChange={boxHeight => setBoxHeight(boxHeight)}
        />
      )}
      <Wrapper>
        <Gap gap={`${boxHeight / 2}px`} />
        <Col alignSelf="center" width="100%" maxWidth="1280px">
          <Row responsive width="100%">
            <Col>
              <FooterTitle>Kontakt</FooterTitle>
              <Block iconName="pin">
                Korunní 860/20
                <br />
                120 00  Praha 2 - Vinohrady
              </Block>
              <Text.Footer>
                <T>Kontaktní osoby</T>:<br />
              </Text.Footer>
              <Gap gap="8px" />
              <Block iconName="person" smallGap>
                Bc. Františka Zadražilová
              </Block>
              <Block iconName="phone" smallGap>+420 777 677 759</Block>
              <Block iconName="email">kurzy@a-t-c.cz</Block>

              <Block iconName="person" smallGap>
                Adéla Krylová
              </Block>
              <Block iconName="email">info@a-t-c.cz</Block>

            </Col>

            <Gap.Fluid min="24px" max="190px" mobileMin="36px" />

            <Col>
              <FooterTitle>Fakturační údaje</FooterTitle>
              <Text.Footer>
                Akreditované tréninkové centrum metod pro osobní rozvoj, z. ú.<br />
                Stejskalova 192/9, Libeň, 180 00  Praha 8 <br />
                <T>IČ</T>: 04610431<br />
                <T>DIČ</T>: CZ04610431
              </Text.Footer>
              <Gap gap="48px" />

              <FooterTitle>Bankovní spojení</FooterTitle>
              <Text.Footer>
                <T>číslo účtu</T>: 2800950775/2010 ( <T>FIO banka</T>)
                <br />
                IBAN: CZ7420100000002800950775
              </Text.Footer>
            </Col>

            <Gap.Fluid min="24px" max="190px" mobileMin="36px" />

            <Col>
              <FooterTitle>Statutární orgán</FooterTitle>
              <Text.Footer>
                PaedDr. Eva Váňová <br />
                <T>ředitelka</T>
              </Text.Footer>

              <Gap gap="48px" />

              <FooterTitle>Doporučujeme</FooterTitle>

              <Text.Footer>
                <Block isListPoint>
                  <Link
                    noStyle
                    asA
                    href="http://www.dyscentrum.org"
                    target="_blank"
                  >
                    www.dyscentrum.org
                  </Link>
                </Block>
                <Block isListPoint>
                  <Link
                    noStyle
                    asA
                    href="http://www.centrumrozum.cz"
                    target="_blank"
                  >
                    www.centrumrozum.cz
                  </Link>
                </Block>
                <Block isListPoint>
                  <Link
                    noStyle
                    asA
                    href="http://www.charliekarlin.cz"
                    target="_blank"
                  >
                    www.charliekarlin.cz
                  </Link>
                </Block>
                <Block isListPoint>
                  <Link
                    noStyle
                    asA
                    href="http://www.ucime-se-ucit.cz"
                    target="_blank"
                  >
                    www.ucime-se-ucit.cz
                  </Link>
                </Block>
              </Text.Footer>
            </Col>
          </Row>
        </Col>
        <Col
          alignItems="center"
          maxWidth="1280px"
          alignSelf="center"
          width="100%"
        >
          <Gap gap="80px" />
          <Line color="#445b57" />
          <Gap gap="40px" mobileGap="24px" />
          <Logo src={logoPath} alt="logo ATC" />
          <Gap gap="54px" mobileGap="36px" />
        </Col>
      </Wrapper>
    </>
  )
}

Footer.defaultProps = {}

Footer.propTypes = {}

export default Footer
