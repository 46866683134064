import React from 'react'
import styled, { css } from 'styled-components'

import { Menu, Footer } from '../components'
import CookiesFooter from '../components/CookiesFooter'
import { up } from '../lib/styles'
import App from './App'
import pagesConfig from '../../pagesConfig'
// import AllPopups from '../components/AllPopups'

const PageWrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`

const FixedWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  pointer-events: none;
`

// eslint-disable-next-line react/prefer-stateless-function
class DefaultLayout extends React.Component {
  render() {
    // these props are exactly same as pageProps injected by gatsby
    // console.log('this.props LAYOUT', this.props)
    const {
      location: { pathname },
      pageContext,
      path,
    } = this.props

    const { pageKey } = pageContext
    const pageConfig = pagesConfig[pageKey] || {}

    const footerBoxVariant = pageConfig.footerBox
    const { footerBoxBg } = pageConfig

    return (
      <App pageContext={pageContext}>
        {/* <AllPopups /> */}
        <PageWrapper>
          <Menu pathname={pathname} pageContext={pageContext} />
          {this.props.children}
          <Footer boxVariant={footerBoxVariant} footerBoxBg={footerBoxBg} />
        </PageWrapper>
        {/* <FixedWrapper>
          <CookiesFooter style={{ pointerEvents: 'auto' }} />
        </FixedWrapper> */}
      </App>
    )
  }
}

export default DefaultLayout
