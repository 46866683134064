import React, { Component } from 'react'
import axios from 'axios'

import IntlContext from './IntlContext'
import intlUtils from '.'

class IntlProvider extends Component {
  state = {
    language: (this.props.intl && this.props.intl.language) || intlUtils.defaultLanguage,
    // tady mohou byt i fce!
    setLanguage: language => {
      window.localStorage.setItem('virtii-intl-lang', language)
      this.setState({ language })
      // console.log('LANG set!', language)
    },
  }

  // componentDidMount() {
  //   this.autoRedirect()
  // }

  // autoRedirect = () => {
  //   const {
  //     intl: { disableAutoRedirect, allPaths, path, language },
  //   } = this.props
  //   // console.log('allPaths', allPaths)

  //   // Skip build, Browsers only, and only for some pages
  //   if (typeof window === 'undefined' || disableAutoRedirect) {
  //     // store it (use case: I have cs and I come again from google to en with no redir. Then it should be en.)
  //     window.localStorage.setItem('virtii-intl-lang', language)
  //     return
  //   }

  //   const savedLang = window.localStorage.getItem('virtii-intl-lang')
  //   if (savedLang) {
  //     // if savedLang, we do redirect on that saved lang... user was there and set his lang
  //     if (!['/en', '/de', '/en/', '/de/'].includes(path)) {
  //       this.performRedirect(savedLang)
  //     } else {
  //       // when comes to root, save the lang of the root
  //       window.localStorage.setItem('virtii-intl-lang', language)
  //     }
  //     return
  //   }

    // console.log('get geo lang ')
  //   axios({
  //     url: '/api/v1/translations/ip-lang',
  //     method: 'get',
  //   })
  //     .then(res => {
  //       // console.log(' res.data',  res.data.country)
  //       let detected = intlUtils.defaultLanguage
  //       if (res.data && res.data.countryCode) {
  //         detected = intlUtils.getLangByCountry(res.data.countryCode)
  //       }

  //       // console.log('detected', detected)
  //       this.performRedirect(detected)
  //     })
  //     .catch(error => {
  //       // console.log('error 1', error)
  //       this.performRedirect(intlUtils.defaultLanguage)
  //     })
  // }

  // redirect page to different lang
  // performRedirect = toLang => {
  //   const {
  //     trimmedPath,
  //     intl: { allPaths, language },
  //   } = this.props
  //   // only if its different
  //   // console.log('allPaths', allPaths)
  //   if (language !== toLang && allPaths[toLang]) {
  //     // console.log('redir', language, toLang)
  //     window.localStorage.setItem('virtii-intl-lang', toLang)
  //     window.location.replace(allPaths[toLang])
  //   }
  // }

  render() {
    // intl settings from gatsby-node unique for every page
    const { intl } = this.props
    const value = {
      ...intl,
      // ...this.state, // current state overrides page settings
    }

    return (
      <IntlContext.Provider value={value}>
        {this.props.children}
      </IntlContext.Provider>
    )
  }
}

export default IntlProvider
