// key is filename for templates or autogenerated path fo static pages
// eslint-disable-next-line no-multi-assign
module.exports = exports = {
  '/': {
    menu: {
      // absolute: true,
    },
    to: {
      cs: '/',
      en: '', // auto prepend with /en
    },
    footerBox: {
      en: 'contact',
    },
    metaDesc: {
      cs:
        'Vše o ATC metod pro osobní rozvoj. Rozcestník pro zájemce o metodu instrumentálního uvědomování (MIU), nabídka kurzů.',
      en:
        'All about the ATC of personal development methods. Signpost for those interested in Method of Instrumental Awareness (MIA), open courses.',
    },
  },
  '/blog': {
    to: {
      cs: '/aktuality',
      en: '/blog',
    },
    footerBox: {
      en: 'contact',
    },
    metaDesc: {
      cs:
        'Co je nového v instrumentálním uvědomování (MIU) v Česku i na Slovensku. Novinky, konference, události.',
      en:
        'News about instrumental enrichment (FIE)in Czechia and Slovakia. News, conferences, events.',
    },
  },
  '/about': {
    to: {
      cs: '/o-nas',
      en: '/about-us',
    },
    footerBox: {
      en: 'contact',
    },
    metaDesc: {
      cs:
        'Seznamte se s týmem ATC, jeho spolupracovníky a partnery. Výroční zprávy. Dokumenty ke stažení.',
      en:
        'Meet the ATC team, its associates and partners. Annual reports. Documents to download.',
    },
  },
  '/collaborators': {
    to: {
      cs: '/skoly-lektori',
      en: '/schools-and-mediators',
    },
    footerBox: 'missing',
    footerBoxBg: 'bgGrey',
    metaDesc: {
      cs:
        'Přehled škol, institucí a lektorů využívajících metodu MIU. Setkejte se s MIU ve svém okolí!',
      en:
        'Overview of schools, institutions and mediators practising FIE methods. Meet FIE in your region!',
    },
  },
  '/projects': {
    to: {
      cs: '/o-projektech',
      en: '/about-projects',
    },
    footerBox: {
      cs: 'projects',
      en: 'contact',
    },
    // todo meta -
    metaDesc: {
      cs:
        'Informace o projektové práci ATC při implementaci metody MIU. Chcete s námi spolupracovat na projektu? Napište nám!',
      en:
        'Information on ATC project work in the implementation of the FIE method. Do you want to work with us on the project? Write us!',
    },
  },
  '/methods': {
    to: {
      cs: '/o-metode',
      en: '/on-methods',
    },
    footerBox: {
      cs: 'tasting',
      en: 'contact',
    },
    metaDesc: {
      cs:
        'Dozvěďte se více o tom, jak vznikla metoda MIU, o jejích východiscích i o principu nehodnocení.',
      en:
        'Learn more about the theoretical background of prof. R. Feuerstein, his life and more about the individual methods of FIE.',
    },
  },
  '/methods/feuerstein': {
    to: {
      cs: '/o-metodach/zivot-reuvena-feuersteina',
    },
    footerBox: 'tasting',
    metaDesc:
      'Dozvěďte se více o teoretických východiscích prof. R. Feuersteina, jeho životě i blíže o jednotlivých metodách FIE.',
  },
  '/methods/approach': {
    to: {
      cs: '/o-metodach/feuersteinuv-pristup',
    },
    footerBox: 'tasting',
    metaDesc:
      'Dozvěďte se více o teoretických východiscích prof. R. Feuersteina, jeho životě i blíže o jednotlivých metodách FIE.',
  },
  '/methods/theory': {
    to: {
      cs: '/o-metodach/teoreticka-vychodiska',
    },
    footerBox: 'tasting',
    metaDesc:
      'Dozvěďte se více o teoretických východiscích prof. R. Feuersteina, jeho životě i blíže o jednotlivých metodách FIE.',
  },
  '/methods/lpad': {
    to: {
      cs: '/o-metodach/lpad',
    },
    footerBox: 'tasting',
    metaDesc:
      'Dozvěďte se více o teoretických východiscích prof. R. Feuersteina, jeho životě i blíže o jednotlivých metodách FIE.',
  },
  '/methods/miu': {
    to: {
      cs: '/o-metodach/miu',
    },
    footerBox: 'tasting',
    metaDesc: 'Dozvěďte se více o Metodě instrumentálního uvědomování (MIU)',
  },
  '/methods/enrichment': {
    to: {
      cs: '/o-metodach/instrumentalni-obohacovani',
    },
    footerBox: 'tasting',
    metaDesc:
      'Dozvěďte se více o teoretických východiscích prof. R. Feuersteina, jeho životě i blíže o jednotlivých metodách FIE.',
  },
  '/courses': {
    to: {
      cs: '/nabizene-kurzy',
    },
    metaDesc:
      'Aktuální nabídka akreditovaných vzdělávacích programů - kurzů, ochutnávek a seminářů (Standard, Basic).',
  },
  '/journey': {
    to: {
      cs: '/cesta-lektora',
    },
    footerBox: 'supervision',
    footerBoxBg: 'bgGrey',
    metaDesc:
      'Přehled možností vzdělávání i následného rozvoje absolventů v užívání metod instrumentálního obohacování (FIE) a dynamického hodnocení (LPAD).',
  },
  '/thanks': {
    to: {
      cs: '/prihlaseni-uspesne',
    },
    footerBox: 'contact',
  },
  '/styles': {
    to: {
      cs: '/style-guide',
    },
  },
  '/temp': {
    to: {
      cs: '/temp',
    },
  },
  PostTemplate: {
    to: {
      cs: '/aktuality',
      en: '/blog',
    },
    withSlugs: true, // all template needs slugs
    metaDesc: {
      cs: '',
      en: '',
    },
    footerBox: {
      en: 'contact',
    },
  },
  ProjectTemplate: {
    to: {
      cs: '/o-projektech',
      en: '/about-projects',
    },
    withSlugs: true, // all template needs slugs
    footerBox: {
      cs: 'projects',
      en: 'contact',
    },
    metaDesc: {
      cs: '',
      en: '',
    },
  },
  CourseTemplate: {
    to: {
      cs: '/nabizene-kurzy',
    },
    withSlugs: true, // all template needs slugs
    footerBox: 'tasting',
    metaDesc: {
      cs: '',
      en: '',
    },
  },
  '/404': {
    footerBox: 'none',
    to: {
      cs: '/404',
      // en: '/404',
    },
  },
  '/404.html': {
    footerBox: 'none',
    to: {
      cs: '/404',
      // en: '/404',
    },
  },
}
