// external imports
import React, { Fragment } from 'react'
import Img from 'gatsby-image'

import SEO from './SEO'
import {
  Page,
  PageContent,
  Gap,
  Row,
  Col,
  HeaderBar,
  Testimonials,
  Title,
} from '.'
import headerBarImageSrc from '../data/images/method-detail-top.png'

const Content = ({ images = [], children, mobileMaxWidth = 'unset' }) => (
  <Row
    width="100%"
    alignItems="flex-start"
    mobileAlignItems="center"
    responsive
  >
    <Col maxWidth="768px" width="100%" mobileMaxWidth={mobileMaxWidth}>
      {children}
    </Col>
    <Gap gap="62px" />
    <Col width="100%" maxWidth="452px">
      {images.map((image, key) => (
        <Fragment key={key}>
          <Img fluid={image.image.fluid} />
          {key !== images.length - 1 && <Gap gap="42px" />}
        </Fragment>
      ))}
    </Col>
  </Row>
)

const MethodPageLayout = ({ images = [], imagesGap = '114px', children }) => (
  <>
    <HeaderBar>
      <HeaderBar.Content shrink={1}>
        <HeaderBar.Title>O metodách</HeaderBar.Title>
        <HeaderBar.SubTitle>
          {/* podrobné informace o prof. feuersteinovi a jeho metodách */}
          Podrobné informace o metodách, autorech, inspiraci, ...
        </HeaderBar.SubTitle>
      </HeaderBar.Content>
      <HeaderBar.ImageWrapper
        padding="10px 80px 5px 20px"
        mobileMaxWidth="350px"
      >
        <img src={headerBarImageSrc} alt="" />
      </HeaderBar.ImageWrapper>
    </HeaderBar>

    <Page grey>
      <PageContent>
        <Gap gap="78px" mobileGap="60px" />
        <Col>{children}</Col>
        <Gap gap="78px" mobileGap="60px" />
      </PageContent>
    </Page>

    <Gap gap="141px" mobileGap="62px" />
    <Page>
      <PageContent>
        <Testimonials />
      </PageContent>
    </Page>
    <Gap gap="198px" mobileGap="97px" />
  </>
)

MethodPageLayout.ParagraphGap = props => <Gap gap="24px" {...props} />
MethodPageLayout.TitleGap = props => <Gap gap="48px" {...props} />

MethodPageLayout.Title = props => <Title.Section {...props} />
MethodPageLayout.SubTitle = props => <Title.SubSection {...props} />
MethodPageLayout.Content = Content

export default MethodPageLayout
